import React from "react";
import { motion } from "framer-motion";
import { Helmet } from "react-helmet";
import googlinessHeroImage from "../../assets/google.png";
import featureImage1 from "../../assets/coding (1).png";
import featureImage2 from "../../assets/rocket_image.png";
import testimonialImage from "../../assets/reviewpicture.jpg";

const GooglinessLandingPage = () => {
  return (
    <>
      {/* SEO Meta Tags */}
      <Helmet>
        <title>Googliness Template | Experience Google's Behavioral Interview</title>
        <meta
          name="description"
          content="Discover what it's like to interview at Google with our Googliness Template. Dive into cultural fit, collaboration, and innovation with a single click."
        />
      </Helmet>

      {/* Main Container */}
      <motion.div
        className="bg-gray-900 text-white font-sans"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        {/* Hero Section */}
        <header className="relative bg-gradient-to-b from-indigo-100 to-gray-[#e0e7ff9e] text-center py-20 px-6">
          <motion.img
            src={googlinessHeroImage}
            alt="Googliness Experience"
            className="mx-auto max-w-[200px] rounded-lg"
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, ease: "easeOut" }}
          />
          <h1 className="text-4xl font-bold mt-8">
            Experience Google's Behavioral Interview
          </h1>
          <p className="text-lg text-gray-300 mt-4">
            Step into the shoes of a Google candidate and discover what it takes to
            embody Googliness—cultural fit, collaboration, and innovation.
          </p>
          <button
            className="mt-8 px-8 py-3 bg-indigo-500 text-white font-semibold rounded-full shadow-lg hover:bg-indigo-600 transition-all duration-300"
            onClick={() => window.location.href = "/run-googliness-interview"}
          >
            Run the Interview
          </button>
        </header>

        {/* Features Section */}
        <section className="py-16 px-6 max-w-[1200px] mx-auto">
          <h2 className="text-3xl font-bold text-center mb-12">
            Why Choose the Googliness Template?
          </h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            {/* Feature 1 */}
            <motion.div
              className="flex flex-col items-center text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <img
                src={featureImage1}
                alt="No Resume Required"
                className="w-60 h-60 object-cover rounded-lg mb-6"
              />
              <h3 className="text-2xl font-semibold">No Resume Required</h3>
              <p className="text-gray-400 mt-4">
                Skip the tedious uploads and get straight into the interview process.
                Everyone starts on a level playing field for an authentic experience.
              </p>
            </motion.div>

            {/* Feature 2 */}
            <motion.div
              className="flex flex-col items-center text-center"
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
            >
              <img
                src={featureImage2}
                alt="Cultural Fit Assessment"
                className="w-60 h-60 object-cover rounded-lg mb-6"
              />
              <h3 className="text-2xl font-semibold">Cultural Fit Assessment</h3>
              <p className="text-gray-400 mt-4">
                Explore how well you align with Google’s values of innovation,
                collaboration, and adaptability through tailored interview questions.
              </p>
            </motion.div>
          </div>
        </section>

        {/* Testimonials Section */}
        <section className="bg-gray-800 py-16 px-6">
          <h2 className="text-3xl font-bold text-center text-white mb-12">
            What Users Are Saying
          </h2>
          <div className="max-w-[800px] mx-auto text-center">
            <motion.div
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, ease: "easeOut" }}
              className="bg-gray-700 p-8 rounded-lg shadow-lg"
            >
              <img
                src={testimonialImage}
                alt="User Testimonial"
                className="w-16 h-16 rounded-full mx-auto mb-4"
              />
              <p className="text-gray-300 italic">
                "This template gave me an incredible insight into what Google looks for
                in candidates. I felt prepared, confident, and inspired!"
              </p>
              <h4 className="text-indigo-400 font-semibold mt-4">— John Doe</h4>
            </motion.div>
          </div>
        </section>

        {/* Call to Action Section */}
        <section className="py-16 px-6 text-center bg-indigo-700 -mb-12">
          <h2 className="text-3xl font-bold text-white">
            Ready to Embody Googliness?
          </h2>
          <p className="text-gray-200 mt-4">
            Click below to start the interview and see how you perform in Google’s
            behavioral assessment.
          </p>
          <button
            className="mt-6 px-8 py-3 bg-white text-indigo-700 font-semibold rounded-full shadow-lg hover:bg-gray-100 transition-all duration-300"
            onClick={() => window.location.href = "/run-googliness-interview"}
          >
            Start Now
          </button>
        </section>

    
      </motion.div>
    </>
  );
};

export default GooglinessLandingPage;

import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./Components/Footer/Footer";
import Navbar from "./Components/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Templates from "./Pages/Templates/Templates.jsx"; 
import bgShadows from "../src/assets/bgShadows.png";
import EachTemplate from "./Pages/EachTemplate/EachTemplate.js";
import TemplatesProcessing from "./Pages/EachTemplate/TemplateProcessing/TemplatesProcessing.jsx";
import AboutUs from "./Pages/Aboutus/Aboutus.jsx";
import ContactUs from "./Pages/Contactus/Contactus.jsx";
import BlogPage from "./Pages/BlogPage/Blogpage.jsx";
import FAANGTeam from "./Pages/FaangTeam/FaangTeam.jsx";
import ErrorPage from "./Pages/ErrorPage/ErrorPage.jsx"; // Import ErrorPage component
import { useEffect, useRef } from "react";
import RenderBlogPage from "./Pages/BlogPage/RenderBlogPage/RenderBlogPage.js";
import TermsAndPrivacy from "./Pages/TermsAndPrivacy/TermsOfUse.jsx";
import PrivacyPolicy from "./Pages/TermsAndPrivacy/PrivacyPolicy.jsx";
import JobCareers from "./Pages/JobCareers/JobCareers.jsx";
import ApplyForJob from "./Pages/JobCareers/ApplyForJob/ApplyForJob.jsx";
import JobSuccess from "./Pages/JobCareers/JobSuccess/JobSuccess.jsx";
import GooglinessLandingPage from "./Pages/TemplatePages/GooglinessLandingPage.js";

function App() {
  const location = useLocation();
  const containerRefscroll = useRef(null);

  useEffect(() => {
    if (containerRefscroll.current) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return (
      <div
        style={{
          backgroundImage: `url(${bgShadows})`,
          backgroundRepeat: "no-repeat",
          background:
            "radial-gradient(100% 149% at 50% 18%, rgb(35 33 74) 0%, rgba(30, 28, 74, 0.96) 0%, rgb(15, 11, 28) 50%, rgb(17 17 17) 100%)",
        }}
        className="min-h-screen flex flex-col"
        ref={containerRefscroll}
      >
          <header className="sticky top-0 z-50">
            <Navbar />
          </header>
          <main className="flex-grow">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/templates" element={<Templates />} />
              <Route path="/aboutus" element={<AboutUs />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route path="/blogs" element={<BlogPage />} />
              <Route path="/career-page" element={<JobCareers/>} />
              <Route path="/career-page/:jobID" element={<ApplyForJob/>} />
              <Route path="/career-page/:jobID/success" element={<JobSuccess/>} />
              <Route path="/blogs/:blogId" element={<RenderBlogPage />} />
              <Route path="/googlinessinterview" element={<GooglinessLandingPage />} />
              <Route path="/faang-team" element={<FAANGTeam />} />
              <Route path="/terms-of-use" element={<TermsAndPrivacy />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/templates/:templateId" element={<EachTemplate />} />
              <Route path="/templates/:templateId/:requestID" element={<TemplatesProcessing />} />
              
              {/* Catch-all Route for 404 page */}
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </main>
          <Footer />
      </div>
  );
}

export default App;
